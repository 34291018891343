import Stack from '@mui/material/Stack';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeviceRCStatus, ListHeaderLabel } from '../../../components';
import DefaultPasswordStatus from '../../../components/DefaultPasswordStatus';
import { RC_STATUS } from '../../../config/devices';
import messages from './messages';

export interface IRemoteConfigurationStatus {
  hasDefaultPassword: boolean,
  isPreventConfigForDefaultPasswordFF?: boolean,
  status: RC_STATUS,
}

function RemoteConfigurationStatus({ hasDefaultPassword, isPreventConfigForDefaultPasswordFF, status }: IRemoteConfigurationStatus) {
  const isDefaultPasswordStatusDisplayed =
    isPreventConfigForDefaultPasswordFF && hasDefaultPassword && ![RC_STATUS.OFFLINE].includes(status);

  return (
    <Stack spacing={0.5} sx={{ width: 'fit-content' }}>
      <ListHeaderLabel>
        <FormattedMessage {...messages.remoteConfigurationStatusLabel} />
      </ListHeaderLabel>
      {isDefaultPasswordStatusDisplayed
        ? <DefaultPasswordStatus />
        : <DeviceRCStatus status={status} />
      }
    </Stack>
  );
}

export default RemoteConfigurationStatus;
