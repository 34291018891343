import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ElIntEnrichedFloorMapping, ElIntStop } from '../../apiRtk/be4feApi';
import { RootState } from '../configureStore';

export const getInitialElevatorIntegrationFloorMappingStore: IElevatorIntegrationFloorMappingStore = {
  floorMapping: { floors: [] },
};

interface IAddElevatorIntegrationFloorMappingAction {
  floorId: string;
  mapping: ElIntStop;
}

interface IEditElevatorIntegrationFloorMappingAction {
  floorId: string;
  value: string;
  mappingIndex: number;
}

interface IDeleteElevatorIntegrationFloorMappingAction {
  floorId: string;
  mappingId: number;
}

export const elevatorIntegrationSlice = createSlice({
  initialState: getInitialElevatorIntegrationFloorMappingStore,
  name: 'elevatorIntegrationFloorMapping',
  reducers: {
    addElevatorIntegration: (state, action: PayloadAction<ElIntEnrichedFloorMapping>) => {
      state.floorMapping.floors = action.payload.floors.map((floor) =>
        action.payload.floorMapping.floors.flatMap((floorMappingFloor) => floorMappingFloor.floorId).includes(floor.id)
          ? action.payload.floorMapping.floors.find((floorMappingFloor) => floorMappingFloor.floorId === floor.id)!
          : { floorId: floor.id, mappings: [{ areaId: '', buildingId: '', elevatorGroupId: '' }] }
      );
    },
    addElevatorIntegrationFloorMappingFloorMapping: (
      state,
      action: PayloadAction<IAddElevatorIntegrationFloorMappingAction>
    ) => {
      state.floorMapping.floors
        .find((floor) => floor.floorId === action.payload.floorId)!
        .mappings.push(action.payload.mapping);
    },
    deleteElevatorIntegrationFloorMapping: (
      state,
      action: PayloadAction<IDeleteElevatorIntegrationFloorMappingAction>
    ) => {
      state.floorMapping.floors
        .find((floor) => floor.floorId === action.payload.floorId)!
        .mappings.splice(action.payload.mappingId, 1);
    },
    editElevatorIntegrationFloorMappingArea: (
      state,
      action: PayloadAction<IEditElevatorIntegrationFloorMappingAction>
    ) => {
      const floorMapping = state.floorMapping?.floors?.find((floor) => floor.floorId === action.payload.floorId)
        ?.mappings[action.payload.mappingIndex];
      if (floorMapping) {
        floorMapping.areaId = action.payload.value;
      }
    },
    editElevatorIntegrationFloorMappingElevatorGroup: (
      state,
      action: PayloadAction<IEditElevatorIntegrationFloorMappingAction>
    ) => {
      const buildingId = action.payload.value.split('|')[0];
      const elevatorGroupId = action.payload.value.split('|')[1];
      const floorMapping = state.floorMapping?.floors?.find((floor) => floor.floorId === action.payload.floorId)
        ?.mappings[action.payload.mappingIndex];
      if (floorMapping) {
        floorMapping.buildingId = buildingId;
        floorMapping.elevatorGroupId = elevatorGroupId;
        floorMapping.areaId = '';
      }
    },
  },
});

export interface IElevatorIntegrationFloorMappingStore extends Pick<ElIntEnrichedFloorMapping, 'floorMapping'> {}

export const selectElevatorIntegration = (state: RootState) => state.elevatorIntegrationFloorMapping;
export default elevatorIntegrationSlice.reducer;
// my2nApi.queries.elevatorIntegrationFloorMapping({"companyId":22494,"siteId":84644})datafloorMapping
