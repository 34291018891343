import http, { encode } from './index';

export const getUsers = async (params) => http.get('/users', { params });

export const getSites = async (params) => http.get('/private/sites', { params });

export const getDevices = async (params) => http.get('/devices', { params });

export const getCompanies = async (params) => http.get('/companies', { params });

export const getHeliosOrganizations = async (subjectId) => http.get(`/heg/organizations/${encode(subjectId)}`);

export const addRegistration = async (values) => http.post('/registration', values);

export const getRegistrations = async (params) => http.get('/registration', { params });

export const resendEmail = async (values) => http.post(`/registration/${encode(values.id)}`, values);

export const getBatches = async (query, params) => http.post('/promo/batches/search', { query }, { params });

export const getBatchDetail = async (batchId) => http.get(`/promo/batches/${encode(batchId)}`);

export const getBatchCodes = async (batchId, params = {}) =>
  http.get(`/promo/batches/${encode(batchId)}/codes`, { params });

export const closeBatch = async (batchId, data) =>
  http.delete(`/promo/batches/${encode(batchId)}`, {
    data,
  });

// Request in body:
// {
//   "size":"5",
//   "name": "axis codes",
//   "chanel":"PROMO",
//   "validityTo":"2123-03-09T17:37:23.322Z",
//   "invoice":"djnkldf-dasdn-3123",
//   "utm":"1234n5435-34123",
//   "notes":"notes",
//   "configuration":{
//   "type": "MOBILE_VIDEO_POOL_CREDIT",
//     "credit": "5"
// },
//   "context":{
//   "companyId":{{companyId}},
//   "siteId":{{siteId}}
// }
// }
export const createBatch = async (values) => http.post('/promo/batches', values);

export const getSerialNumbers = async (params) => http.get('/logsettings/acs', { params });

export const switchLogging = async (serialNumber, values) =>
  http.put(`/logsettings/acs/${encode(serialNumber)}`, values);

export const removeDeviceFromLoggingList = async (serialNumber) =>
  http.delete(`/logsettings/acs/${encode(serialNumber)}`);
