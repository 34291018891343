import { yupResolver } from '@hookform/resolvers/yup';
import DoneIcon from '@mui/icons-material/Done';
import { Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { GuestPinSettingsDto, useUpdateSiteGuestPinSettingsMutation } from '../../../apiRtk/accessControlApi';
import { useGetSiteQuery } from '../../../apiRtk/be4feApi';
import Checkbox from '../../../components/HookFormFields/Checkbox';
import { OutlinedTextField } from '../../../components/HookFormFields/TextField';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { useGetGlobalInfo } from '../../../hooks/useGetGlobalInfo';
import styles from '../styles';
import messages from './messages';
import { FormFieldNames, getDefaultValues, getValidationSchema } from './validationSchema';

function Access() {
  const { formatMessage } = useGetGlobalInfo();
  const { companyId, siteId } = useGetLoadedParams();
  const { data: siteData, isLoading: siteDataIsLoading } = useGetSiteQuery({ companyId: companyId!, siteId: siteId! });
  const [updateSiteGuestPinSettings, { isLoading: updateSiteGuestPinSettingsIsLoading }] =
    useUpdateSiteGuestPinSettingsMutation();
  const formMethods = useForm({
    defaultValues: getDefaultValues(siteData),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  useEffect(() => {
    formMethods.reset({
      [FormFieldNames.GuestLength]: siteData?.services.ACCESS_CONTROL.guestPinLength,
      [FormFieldNames.GuestQR]: siteData?.services.ACCESS_CONTROL.guestShowQr,
    });
  }, [siteData]);

  function handleSubmit() {
    const formValues = formMethods.getValues();
    const guestPinSettingsDto: GuestPinSettingsDto = {
      guestPinLength: formValues.guestLength,
      guestShowQr: formValues.guestQR,
    };

    formMethods.reset({
      [FormFieldNames.GuestLength]: formValues.guestLength,
      [FormFieldNames.GuestQR]: formValues.guestQR,
    });

    updateSiteGuestPinSettings({
      companyId: companyId!.toString(),
      guestPinSettingsDto,
      siteId: siteId!.toString(),
    });
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Paper sx={{ p: 3 }}>
          <Grid container>
            <Grid item lg={4} md={12} xs={12}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage {...messages.siteSettingsAccessTitle1} />
              </Typography>
              {!siteDataIsLoading && (
                <Stack spacing={3} sx={{ mb: 3, mt: 4 }}>
                  <OutlinedTextField
                    sx={{ width: '167px' }}
                    label={formatMessage(messages.siteSettingsAccessGuestLengthLabel)}
                    name={FormFieldNames.GuestLength}
                    helperText={<FormattedMessage {...messages.siteSettingsAccessGuestLengthHelper} />}
                    InputProps={{
                      endAdornment: (
                        <Typography color="secondary">
                          <FormattedMessage {...messages.siteSettingsAccessGuestLengthUnit} />
                        </Typography>
                      ),
                    }}
                  />
                  <Checkbox
                    name={FormFieldNames.GuestQR}
                    label={<FormattedMessage {...messages.siteSettingsAccessGuestQrLabel} />}
                  />
                </Stack>
              )}
              <Typography>
                <FormattedMessage {...messages.siteSettingsAccessText1} />
              </Typography>
            </Grid>

            <Grid item lg={4} md={12} xs={12}>
              <Typography variant="body1" fontWeight={700}>
                <FormattedMessage {...messages.siteSettingsAccessTitle2} />
              </Typography>
              <Typography variant="body1" sx={{ mt: 3 }}>
                <FormattedMessage {...messages.siteSettingsAccessText2} />
              </Typography>
            </Grid>
            <Grid item xs={12} sx={styles.buttons}>
              <Button color="secondary" onClick={() => formMethods.reset()}>
                <FormattedMessage {...messages.siteSettingsAccessCancel} />
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={
                  !formMethods.formState.isValid ||
                  !formMethods.formState.isDirty ||
                  updateSiteGuestPinSettingsIsLoading
                }
                startIcon={<DoneIcon />}
                variant="contained"
              >
                <FormattedMessage {...messages.siteSettingsAccessSubmit} />
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </FormProvider>
  );
}

export default Access;
