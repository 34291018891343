export const getSearchTypeSelector = ({ superadmin }) => superadmin.searchType;

export const getQuerySelector = ({ superadmin }) => superadmin.query;

export const isSidebarMobile = ({ superadmin }) => superadmin.sidebarMobile.show;

export const getRegistrationCompanySelector = ({ superadmin }) => ({
  canBeRegistered: superadmin.greenRegistration?.data?.canBeRegistered || false,
  name: superadmin.greenRegistration?.data?.name || '',
});

export const getUserDataForGreenRegistrationSelector = ({ superadmin }) => ({
  email: superadmin.greenRegistration?.registrationModal?.email || '',
  firstName: superadmin.greenRegistration?.registrationModal?.firstName || '',
  lastName: superadmin.greenRegistration?.registrationModal?.lastName || '',
  subjectId: superadmin.greenRegistration?.data?.subjectId || null,
});

export const getSerialNumbersListIsLoading = ({ superadmin }) => superadmin.serialNumbersList.isFetching;

export const getSerialNumbersListIsInvalid = ({ superadmin }) => superadmin.serialNumbersList.didInvalid;

export const getSerialNumbersListPage = ({ superadmin }) => superadmin.serialNumbersList.page;

export const getSerialNumbersListRowsPerPage = ({ superadmin }) => superadmin.serialNumbersList.rowsPerPage;

export const getSerialNumbersListResultsFiltered = ({ superadmin }) => superadmin.serialNumbersList.resultsFiltered;

export const getSerialNumbersListData = ({ superadmin }) => superadmin.serialNumbersList.data;

export const getSerialNumbersListFilter = ({ superadmin }) => superadmin.serialNumbersList.filter;
