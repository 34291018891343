import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRedirectOnChange } from '../../hooks/redirectOnChange';
import { getOryLoginStatusSelector } from '../../modules/auth/selectors';
import { useOryLoginApi } from '../../ory/hooks/oryApi';
import { useAppContext } from '../App/AppContextProvider';
import { logoutAndNavigateToLogin } from './logout';

export function LogoutPage(): JSX.Element {
  const { loggedIn } = useAppContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const oryLoginStatus = useSelector(getOryLoginStatusSelector);
  const { logoutFromOry } = useOryLoginApi();

  useRedirectOnChange();

  useEffect(() => logoutAndNavigateToLogin({ dispatch, logoutFromOry, navigate, oryLoginStatus }), [loggedIn]);

  return <></>;
}
