import Typography from '@mui/material/Typography';
import React from 'react';
import Checkbox from '../../../../../components/HookFormFields/Checkbox';
import { MobileVideoType } from '../../../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../../../modules/sites/store';

interface IActivatePaidServicesCheckbox {
  initialPaidServicesActiveValue: boolean;
  label: string;
  licenceModel: IMVLicenceModel;
  onOpenActivatePaidServicesModal: () => void;
}

function ActivatePaidServicesCheckbox({
  initialPaidServicesActiveValue,
  label,
  licenceModel,
  onOpenActivatePaidServicesModal,
}: IActivatePaidServicesCheckbox) {
  const { licencesLimit, paidCount, type } = licenceModel;
  const isMVTypeStandard = type === MobileVideoType.STANDARD;

  const handleChange = (event: any) => {
    const isChecked: boolean = event.target.checked && event.target.checked !== initialPaidServicesActiveValue;
    if (isChecked && !sessionStorage.getItem('paidModal') && isMVTypeStandard && paidCount >= licencesLimit) {
      onOpenActivatePaidServicesModal();
    }
  };

  return <Checkbox label={<Typography>{label}</Typography>} onInput={handleChange} name="paidServicesActive" />;
}

export default ActivatePaidServicesCheckbox;
