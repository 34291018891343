import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useElevatorIntegrationFloorMappingQuery } from '../../../../../apiRtk/be4feApi';
import { useGetLoadedParams } from '../../../../../hooks/paramsHooks';
import messages from '../messages';

function BuildingIds() {
  const { companyId, siteId } = useGetLoadedParams();
  const { data: siteBuildingTopology } = useElevatorIntegrationFloorMappingQuery({
    companyId: companyId!,
    siteId: siteId!,
  });
  return (
    <>
      {siteBuildingTopology?.buildingTopology.buildings.map((building, index) => {
        return (
          <FormattedMessage
            key={index}
            {...messages.siteSettingsKoneCloudConnectionInfoText}
            values={{
              br: <br />,
              buildingId: building.id,
              index: index + 1,
            }}
          />
        );
      })}
    </>
  );
}

export default BuildingIds;
