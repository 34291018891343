import DoneIcon from '@mui/icons-material/Done';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useElevatorIntegrationFloorMappingQuery } from '../../../apiRtk/be4feApi';
import {
  SiteRequestDto,
  useGetSiteElevatorIntegrationQuery,
  useUpdateSiteFloorMappingMutation,
  useUpdateSiteMutation,
} from '../../../apiRtk/elevatorIntegrationApi';
import { useTypedSelector } from '../../../modules/configureStore';
import { selectors as elevatorIntegrationSelectors } from '../../../modules/elevatorIntegration';
import { elevatorIntegrationSlice, selectElevatorIntegration } from '../../../modules/my2nApi/elevatorIntegrationSlice';
import { TYPE_DESCRIPTOR } from '../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../modules/notifications/actions';
import styles from '../../SiteUserDetail/Detail/UserInfoForm/styles/UserInfoForm';
import ElevatorIntegrationInfo from './ElevatorIntegrationInfo/ElevatorIntegrationInfo';
import FloorConfiguration from './FloorConfiguration';
import { getFloorMappingDto } from './helpers';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import IntegrationSettings from './IntegrationSettings';
import KoneCloudConnection from './KoneCloudConnection';
import messages from './messages';

export function ElevatorIntegration() {
  const hasSiteElevatorIntegration = useSelector(elevatorIntegrationSelectors.isSiteElevatorIntegrationActive);
  const { companyId, dispatch, formMethods, siteId } = useGetGlobalHooks();
  const [updateSite, { isLoading: updateSiteIsLoading }] = useUpdateSiteMutation();
  const [updateSiteFloorMapping, { isLoading: updateSiteFloorMappingIsLoading }] = useUpdateSiteFloorMappingMutation();
  const elevatorIntegrationFloorMappingStore = useTypedSelector(selectElevatorIntegration);
  const { data: siteElevatorIntegrationFloorMappingData } = useElevatorIntegrationFloorMappingQuery(
    {
      companyId: Number(companyId),
      siteId: Number(siteId),
    },
    { skip: !hasSiteElevatorIntegration }
  );
  const {
    data: siteElevatorIntegrationConfiguration,
    isLoading: siteElevatorIntegrationConfigurationIsLoading,
    refetch: refetchSiteElevatorIntegrationConfiguration,
  } = useGetSiteElevatorIntegrationQuery(
    {
      companyId: companyId.toString(),
      siteId: siteId.toString(),
    },
    { refetchOnMountOrArgChange: true, skip: !hasSiteElevatorIntegration }
  );

  useEffect(() => {
    setFormValues();
  }, [siteElevatorIntegrationConfigurationIsLoading]);

  useEffect(() => {
    if (siteElevatorIntegrationFloorMappingData) {
      dispatch(elevatorIntegrationSlice.actions.addElevatorIntegration(siteElevatorIntegrationFloorMappingData));
    }
  }, [siteElevatorIntegrationFloorMappingData]);

  function getSiteRequest() {
    return formMethods.getValues() as SiteRequestDto;
  }

  function refreshData() {
    refetchSiteElevatorIntegrationConfiguration();
    setFormValues();
  }

  function setFormValues() {
    if (siteElevatorIntegrationConfiguration) {
      Object.keys(siteElevatorIntegrationConfiguration)
        .filter((key: string) => key !== 'buildingId')
        .forEach((key: keyof SiteRequestDto) => formMethods.setValue(key, siteElevatorIntegrationConfiguration[key]));
    }
  }

  async function handleSubmit() {
    await updateSite({
      companyId,
      siteId,
      siteRequestDto: getSiteRequest(),
    })
      .unwrap()
      .catch(() => refetchSiteElevatorIntegrationConfiguration());
    await updateSiteFloorMapping({
      companyId,
      floorMappingDto: getFloorMappingDto(elevatorIntegrationFloorMappingStore.floorMapping.floors),
      siteId,
    })
      .unwrap()
      .then(() => {
        dispatch(addSnackbarSuccess({ ...messages.elevatorIntegrationMsgSucc }));
      })
      .catch(() => {
        dispatch(addSnackbarError({ ...messages.elevatorIntegrationMsgError }, TYPE_DESCRIPTOR));
      });
    dispatch(elevatorIntegrationSlice.actions.addElevatorIntegration(siteElevatorIntegrationFloorMappingData!));
  }

  return (
    <Paper sx={{ p: 3 }}>
      {hasSiteElevatorIntegration ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Grid container spacing={4}>
              <Grid item lg={4} md={12} xs={12}>
                <KoneCloudConnection />
                <IntegrationSettings />
              </Grid>
              <Grid item lg={8} md={12} xs={12}>
                <FloorConfiguration />
              </Grid>
              <Grid item xs={12} sx={styles.buttons}>
                <Button color="primary" onClick={refreshData}>
                  <FormattedMessage {...messages.elevatorIntegrationCancel} />
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={!formMethods.formState.isValid || updateSiteIsLoading || updateSiteFloorMappingIsLoading}
                  startIcon={<DoneIcon />}
                  variant="contained"
                >
                  <FormattedMessage {...messages.elevatorIntegrationSubmit} />
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      ) : (
        <ElevatorIntegrationInfo />
      )}
    </Paper>
  );
}
