import DoneIcon from '@mui/icons-material/Done';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useMediaQuery, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDanger from '../ButtonDanger';
import ConfirmButton from '../ConfirmButton';
import messages from './messages';

const styles = {
  accordion: {
    '& .Mui-disabled img,.Mui-disabled svg': {
      filter: 'grayscale(100%)',
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      my: 1.5,
    },
  },
  img: {
    '& img, svg': {
      height: '40px',
      width: '40px',
    },
    width: (theme) => theme.spacing(7),
  },
  inactiveImg: {
    '& img': {
      filter: 'grayscale(100%)',
      opacity: (theme) => theme.palette.action.disabledOpacity,
    },
  },
  notSet: {
    cursor: 'default',
  },
};

// eslint-disable-next-line complexity
const ServicePanel = ({
  active,
  children,
  disabled,
  hasDefaultPassword,
  icon,
  isPreventConfigForDefaultPasswordFF,
  isServiceAvailable,
  name,
  onToggleConfirm,
  state,
  toggleConfirmInfo,
  toggleConfirmTitle,
}) => {
  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isServiceNotSet = disabled && isServiceAvailable;

  const getSummaryInfoSettings = () =>
    upLg
      ? {
          alignItems: 'center',
          direction: 'row',
          spacing: 2,
        }
      : {
          alignItems: 'flex-start',
          direction: 'column',
        };

  const getButtonContainerSettings = () =>
    downSm
      ? {
          justifyContent: 'flex-start',
        }
      : {
          columnSpacing: 1,
          justifyContent: 'flex-end',
        };

  const getStateColor = () => {
    if (isPreventConfigForDefaultPasswordFF && hasDefaultPassword) {
      return 'error.main';
    }
    if (!(isPreventConfigForDefaultPasswordFF && hasDefaultPassword) && active && !disabled) {
      return 'success.main';
    }
    if (!(isPreventConfigForDefaultPasswordFF && hasDefaultPassword) && !active && !disabled) {
      return 'warning.main';
    }
    return 'text.primary';
  };

  return (
    <Box mb={3}>
      <Accordion sx={styles.accordion}>
        <AccordionSummary
          disabled={disabled && !isServiceAvailable}
          expandIcon={!isServiceNotSet && <ExpandMoreIcon />}
          sx={{ ...(styles.notSet && isServiceNotSet) }}
        >
          <Grid
            alignItems="flex-start"
            container
            sx={{
              ...styles.img,
              ...(((!active && !disabled) || isServiceNotSet) && styles.inactiveImg),
            }}
          >
            {icon && (
              <Grid item>
                <Box pr={2} width={40}>
                  {icon}
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid alignItems="center" container item xs>
            <Grid {...getSummaryInfoSettings()} container item justifyContent="flex-start" md={8} xs={12}>
              {name && (
                <Grid item>
                  <Typography component="div">{name}</Typography>
                </Grid>
              )}
              {state && !disabled && (
                <Grid mt={0.4} item>
                  <Typography component="div">
                    <Box color={getStateColor()}>{state}</Box>
                  </Typography>
                </Grid>
              )}
              {isServiceNotSet && (
                <Grid mt={0.4} item>
                  <Typography component="div">
                    <strong>
                      <FormattedMessage {...messages.servicePanelStateDisabled} />
                    </strong>
                  </Typography>
                </Grid>
              )}
              {disabled && !isServiceNotSet && (
                <Grid mt={0.4} item>
                  <Typography component="div">
                    <strong>
                      <FormattedMessage {...messages.servicePanelStateUnavailable} />
                    </strong>
                  </Typography>
                </Grid>
              )}
            </Grid>
            {onToggleConfirm && isServiceAvailable && (
              <Grid {...getButtonContainerSettings()} container item xs>
                <Grid item>
                  <Box pr={1}>
                    <ConfirmButton
                      confirmInfo={toggleConfirmInfo}
                      dialogHeaderVariant={active ? 'danger' : 'primary'}
                      dialogTitle={toggleConfirmTitle}
                      disableButtonText={
                        <div>
                          <Box sx={{ display: { lg: 'block ', xs: 'none' } }}>
                            <FormattedMessage {...messages.servicePanelButtonDisableShort} />
                          </Box>
                          <Box sx={{ display: { lg: 'none ', xs: 'block' } }}>
                            <FormattedMessage {...messages.servicePanelButtonDisable} />
                          </Box>
                        </div>
                      }
                      enableButtonText={
                        <div>
                          <Box sx={{ display: { lg: 'block ', xs: 'none' } }}>
                            <FormattedMessage {...messages.servicePanelButtonEnableShort} />
                          </Box>
                          <Box sx={{ display: { lg: 'none ', xs: 'block' } }}>
                            <FormattedMessage {...messages.servicePanelButtonEnable} />
                          </Box>
                        </div>
                      }
                      isActive={active}
                      onConfirm={onToggleConfirm}
                      submitButton={(onConfirm) => (
                        <>
                          {active ? (
                            <ButtonDanger onClick={onConfirm} startIcon={<HighlightOffIcon />}>
                              <FormattedMessage {...messages.servicePanelDialogButtonTextDeactivate} />
                            </ButtonDanger>
                          ) : (
                            <Button color={'primary'} onClick={onConfirm} startIcon={<DoneIcon />} variant="contained">
                              <FormattedMessage {...messages.servicePanelDialogButtonTextActivate} />
                            </Button>
                          )}
                        </>
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        {!disabled && (
          <AccordionDetails>
            <Grid container direction="column">
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <Box py={2}>{children}</Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

ServicePanel.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  hasDefaultPassword: PropTypes.bool,
  icon: PropTypes.element,
  isPreventConfigForDefaultPasswordFF: PropTypes.bool,
  isServiceAvailable: PropTypes.bool,
  name: PropTypes.element,
  onToggleConfirm: PropTypes.func,
  state: PropTypes.element,
  toggleConfirmInfo: PropTypes.node.isRequired,
  toggleConfirmTitle: PropTypes.node
};

ServicePanel.defaultProps = {
  active: false,
  disabled: false,
  icon: null,
  isServiceAvailable: true,
  name: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggleConfirm: () => {},
  state: null,
  toggleConfirmTitle: null,
};

export default ServicePanel;
