import ErrorIcon from '@mui/icons-material/Error';
import ReportProblem from '@mui/icons-material/ReportProblem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../../../components/Alert';
import { useHasFeature } from '../../../components/Feature/Feature';
import featureFlags from '../../../config/featureFlags';
import messages from '../messages';

function DefaultPasswordAlert() {
  const isPreventConfigForDefaultPasswordFF = useHasFeature(featureFlags.PREVENT_CONFIG_FOR_DEFAULT_PASSWORD);

  return (
    <Alert
      icon={isPreventConfigForDefaultPasswordFF ? <ErrorIcon /> : <ReportProblem />}
      severity={isPreventConfigForDefaultPasswordFF ? "error" : "warning" }
    >
      <Typography color="warning.text" variant="body2">
        <FormattedMessage {...(isPreventConfigForDefaultPasswordFF
          ? messages.DeviceListDefaultPasswordError
          : messages.deviceListDefaultPasswordWarning)}
        />
      </Typography>
    </Alert>
  );
}

export default DefaultPasswordAlert;
