import { put, select } from 'redux-saga/effects';
import { DEVICE_TYPE } from '../../../config/devices';
import { selectors as companySelectors } from '../../../modules/company';
import { types } from '../../../modules/devices';
import { selectors as siteSelectors, types as siteTypes } from '../../../modules/sites';
import addDeviceAxisDoorStationAsync from './axisDoorStation';
import addDeviceHipmoOrThirdPartyComposite from './hipmoOrThirdPartyComposite';
import add2NDevice from './x2nDevice';

// eslint-disable-next-line max-statements,complexity
export default function* addDeviceSaga(props) {
  const { deviceType, formActions } = props;
  yield formActions.setStatus({});
  yield formActions.setSubmitting(true);
  try {
    let data = {};
    const options = {
      ...props,
      company: yield select(companySelectors.getCompanyData),
      site: yield select(siteSelectors.getSiteData),
    };
    switch (deviceType) {
      case 'X2N':
        data = yield add2NDevice(options);
        break;
      case DEVICE_TYPE.HIPMO:
      case DEVICE_TYPE.THIRD_PARTY:
        data = yield addDeviceHipmoOrThirdPartyComposite(options);
        break;
      case DEVICE_TYPE.DOOR_STATION:
        data = yield addDeviceAxisDoorStationAsync(options);
        break;
      default:
        throw new Error('Unknown device type.');
    }

    if (deviceType === 'X2N' && data?.device?.type !== DEVICE_TYPE.IDT) {
      yield put({ payload: data?.device, type: types.ADD_DEVICE_WAIT });
    } else {
      yield put({ type: siteTypes.INVALIDATE_SITE_DASHBOARD });
      yield put({ payload: data?.device, type: types.ADD_DEVICE_SUCCESS });
    }

    yield put({ data: data?.device, type: types.ADD_DEVICE_FEATURES });
  } catch (error) {
    if (error?.data?.code === 'IV66') {
      yield formActions.setStatus({
        msg: 'Apartment device limit reached.',
        type: 'error',
      });
    } else if (error?.message !== 'skip') {
      yield formActions.setStatus({
        msg: 'An error occurred while adding the device.',
        type: 'error',
      });
    }
  } finally {
    yield formActions.setSubmitting(false);
  }
}
