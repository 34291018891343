import ErrorIcon from '@mui/icons-material/Error';
import ReportProblem from '@mui/icons-material/ReportProblem';
import Button from '@mui/material/Button';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '../../../components';
import { FIRMWARE_STATUS, RC_STATUS } from '../../../config/devices';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import messages from './messages';

interface IChangePasswordNotificationProps {
  remoteConfigurationStatus: string;
  firmwareStatus: string;
  isPreventConfigForDefaultPasswordFF?: boolean;
  onOpenRCModal: (device: IDevice) => void;
  device: IDevice;
}

export function DefaultPasswordNotification({
  device,
  firmwareStatus,
  isPreventConfigForDefaultPasswordFF,
  onOpenRCModal,
  remoteConfigurationStatus,
}: IChangePasswordNotificationProps) {
  const { formatMessage } = useIntl();
  const alertProps = useMemo(() => {
    if (remoteConfigurationStatus === RC_STATUS.DISABLED) {
      return {
        button: false,
        descriptionError: messages.DeviceDetailDefaultPasswordErrorTextInactiveRC,
        descriptionWarning: messages.DeviceDetailDefaultPasswordWarningTextInactiveRC,
      };
    }
    if (remoteConfigurationStatus === RC_STATUS.OFFLINE) {
      return {
        button: false,
        descriptionError: messages.DeviceDetailDefaultPasswordErrorTextOffline,
        descriptionWarning: messages.DeviceDetailDefaultPasswordWarningTextOffline,
      };
    }
    if (remoteConfigurationStatus !== RC_STATUS.OFFLINE && remoteConfigurationStatus !== RC_STATUS.DISABLED) {
      if (firmwareStatus === FIRMWARE_STATUS.SUPPORTED_NEWER || firmwareStatus === FIRMWARE_STATUS.SUPPORTED_OLDER) {
        return {
          button: true,
          descriptionError: messages.DeviceDetailDefaultPasswordErrorTextOutofdateFW,
          descriptionWarning: messages.DeviceDetailDefaultPasswordWarningTextOutofdateFW,
        };
      }
      if (firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED || firmwareStatus === FIRMWARE_STATUS.DEPRECATED) {
        return {
          button: false,
          descriptionError: messages.DeviceDetailDefaultPasswordErrorTextUnsupportedFW,
          descriptionWarning: messages.DeviceDetailDefaultPasswordWarningTextUnsupportedFW,
        };
      }
    }
    return {
      button: true,
      descriptionError: messages.DeviceDetailDefaultPasswordErrorTextActiveRC,
      descriptionWarning: messages.DeviceDetailDefaultPasswordWarningTextActiveRC,
    };
  }, [remoteConfigurationStatus, firmwareStatus]);

  return (
    <Alert
      icon={isPreventConfigForDefaultPasswordFF ? <ErrorIcon /> : <ReportProblem />}
      action={
        alertProps.button ? (
          <Button
            onClick={() => onOpenRCModal(device)}
            size="small"
            sx={
              isPreventConfigForDefaultPasswordFF
                ? { '&:hover': { backgroundColor: 'error.hoverBg' }, color: 'error.text' }
                : { '&:hover': { backgroundColor: 'warning.hoverBg' }, color: 'warning.text' }
            }
          >
            <FormattedMessage {...messages.DeviceDetailChangePasswordButton} />
          </Button>
        ) : undefined
      }
      severity={isPreventConfigForDefaultPasswordFF ? "error" : "warning" }
      title={formatMessage(
        isPreventConfigForDefaultPasswordFF
          ? messages.DeviceDetailDefaultPasswordErrorTitle
          : messages.DeviceDetailDefaultPasswordWarningTitle)}
      variant="standard"
    >
      <FormattedMessage
        {...(isPreventConfigForDefaultPasswordFF
          ? alertProps.descriptionError
          : alertProps.descriptionWarning)}
        values={{ br: <br /> }}
      />
    </Alert>
  );
}
