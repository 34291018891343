import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { PATHS } from '../../config/consts';
import { OryLoginStatus, OryResponse } from '../../ory/types';

export function logoutAndNavigateToLogin({
  dispatch,
  logoutFromOry,
  navigate,
  oryLoginStatus,
}: {
  dispatch: Dispatch;
  navigate: NavigateFunction;
  oryLoginStatus: OryLoginStatus | undefined;
  logoutFromOry: () => Promise<OryResponse>;
}) {
  const tryToLogoutFromOry = async () => {
    await logoutFromOry();
    dispatch({ type: 'store/reset' });
    navigate(PATHS.LOGIN);
  };
  if (oryLoginStatus === OryLoginStatus.SessionConfirmed) {
    tryToLogoutFromOry();
  } else {
    dispatch({ type: 'store/reset' });
    navigate(PATHS.LOGIN);
  }
}
