import { defineMessages } from 'react-intl';

export default defineMessages({
  DeviceDetailChangePasswordButton: {
    defaultMessage: "Change password",
    id: "deviceDetail.changePassword.button"
  },
  DeviceDetailDefaultPasswordErrorTextActiveRC: {
    defaultMessage: "Until you change the default password, any settings made in My2N won't take effect on the device.{br}" +
      "Change the password in the device’s configuration interface or via the Remote Configuration service.",
    id: "deviceDetail.defaultPasswordError.text.activeRC"
  },
  DeviceDetailDefaultPasswordErrorTextInactiveRC: {
    defaultMessage: "Until you change the default password, any settings made in My2N won't take effect on the device.{br}" +
      "Change the password in the device’s configuration interface or activate the Remote Configuration service and change it there.",
    id: "deviceDetail.defaultPasswordError.text.inactiveRC"
  },
  DeviceDetailDefaultPasswordErrorTextOffline: {
    defaultMessage: "Until you change the default password, any settings made in My2N won't take effect on the device.{br}" +
      "Change the password in the device’s configuration interface or via the Remote Configuration service when the device gets back online.",
    id: "deviceDetail.defaultPasswordError.text.offline"
  },
  DeviceDetailDefaultPasswordErrorTextOutofdateFW: {
    defaultMessage: "Until you change the default password, any settings made in My2N won't take effect on the device.{br}" +
      "Change the password in the device’s configuration interface or via the Remote Configuration service.",
    id: "deviceDetail.defaultPasswordError.text.outofdateFW"
  },
  DeviceDetailDefaultPasswordErrorTextUnsupportedFW: {
    defaultMessage: "Until you change the default password, any settings made in My2N won't take effect on the device.{br}" +
      "Change the password in the device’s configuration interface.",
    id: "deviceDetail.defaultPasswordError.text.unsupportedFW"
  },
  DeviceDetailDefaultPasswordErrorTitle: {
    defaultMessage: "Device can’t be configured until you change its default password",
    id: "deviceDetail.defaultPasswordError.title"
  },
  DeviceDetailDefaultPasswordWarningTextActiveRC: {
    defaultMessage: "Enhance security by changing the password in the device’s configuration interface or via the Remote Configuration service.",
    id: "deviceDetail.defaultPasswordWarning.text.activeRC"
  },
  DeviceDetailDefaultPasswordWarningTextInactiveRC: {
    defaultMessage: "To enhance security, change the password in the device’s configuration interface." +
      "Alternatively, you can activate the Remote Configuration service and change it there.",
    id: "deviceDetail.defaultPasswordWarning.text.inactiveRC"
  },
  DeviceDetailDefaultPasswordWarningTextOffline: {
    defaultMessage: "To enhance security, change the password in the device’s configuration interface.{br}" +
      "Alternatively, you can change it via the Remote Configuration service when the device gets back online.",
    id: "deviceDetail.defaultPasswordWarning.text.offline"
  },
  DeviceDetailDefaultPasswordWarningTextOutofdateFW: {
    defaultMessage: "Enhance security by changing the password in the device’s configuration interface or via the Remote Configuration service.",
    id: "deviceDetail.defaultPasswordWarning.text.outofdateFW"
  },
  DeviceDetailDefaultPasswordWarningTextUnsupportedFW: {
    defaultMessage: "To enhance security, change the password in the device’s configuration interface.",
    id: "deviceDetail.defaultPasswordWarning.text.unsupportedFW"
  },
  DeviceDetailDefaultPasswordWarningTitle: {
    defaultMessage: "This device uses the default password",
    id: "deviceDetail.defaultPasswordWarning.title"
  }
});
