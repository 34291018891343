import copy from 'copy-to-clipboard';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/superadminApi';
import * as userApi from '../../api/userApi';
import { PATHS } from '../../config/consts';
import companyActions, { types as companyTypes } from '../company';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarError, addSnackbarSuccess } from '../notifications/actions';
import { types as userTypes } from '../user';
import {
  getRegistrationCompanySelector,
  getSearchTypeSelector,
  getUserDataForGreenRegistrationSelector,
} from './selectors';
import types, { SEARCH_TYPES } from './types';

// eslint-disable-next-line complexity,max-statements
export function* getSuperadminData(action) {
  const { context, filter, order, page, params, rowsPerPage } = action;
  const searchType = yield select(getSearchTypeSelector);
  let response;
  if (searchType === SEARCH_TYPES.COMPANY_SITE) {
    try {
      response = yield call(Api.getSites, params);
      yield responseValidateStatusOrThrow(response);
      const { results, resultsFiltered, resultsTotal } = response.data;
      yield put({
        filter,
        order,
        page,
        payload: {
          data: results,
          resultsFiltered,
          resultsTotal,
        },
        rowsPerPage,
        type: types.GET_SUPERADMIN_SITES_SUCCESS,
      });
    } catch (error) {
      yield put(errorHelper(types.GET_SUPERADMIN_SITES_FAILURE, error));
    }
  } else if (searchType === SEARCH_TYPES.USER) {
    try {
      response = yield call(Api.getUsers, params);
      yield responseValidateStatusOrThrow(response);
      const { results, resultsFiltered, resultsTotal } = response.data;
      yield put({
        context,
        filter,
        order,
        page,
        payload: {
          data: results,
          resultsFiltered,
          resultsTotal,
        },
        rowsPerPage,
        type: types.GET_SUPERADMIN_USERS_LIST_SUCCESS,
      });
    } catch (error) {
      yield put(errorHelper(types.GET_SUPERADMIN_USERS_LIST_FAILURE, error));
    }
  } else if (searchType === SEARCH_TYPES.DEVICE) {
    try {
      response = yield call(Api.getDevices, params);
      yield responseValidateStatusOrThrow(response);
      const { results, resultsFiltered, resultsTotal } = response.data;
      yield put({
        filter,
        order,
        page,
        payload: {
          data: results,
          resultsFiltered,
          resultsTotal,
        },
        rowsPerPage,
        type: types.GET_SUPERADMIN_DEVICES_SUCCESS,
      });
    } catch (error) {
      yield put(errorHelper(types.GET_SUPERADMIN_DEVICES_FAILURE, error));
    }
  }
}

export function* getSuperadminCompanies(action) {
  const { filter, order, page, params, rowsPerPage } = action;
  let response;
  try {
    response = yield call(Api.getCompanies, params);
    yield responseValidateStatusOrThrow(response);
    const { results, resultsFiltered, resultsTotal } = response.data;
    yield put({
      filter,
      order,
      page,
      payload: {
        data: results,
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.GET_SUPERADMIN_COMPANIES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_SUPERADMIN_COMPANIES_FAILURE, error));
  }
}

export function* createGreenRegistration() {
  try {
    const company = yield select(getRegistrationCompanySelector);
    const formValues = yield select(getUserDataForGreenRegistrationSelector);
    const data = {
      action: 'GREEN_REGISTRATION',
      companyName: company.name,
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      subjectId: formValues.subjectId,
    };
    const response = yield call(Api.addRegistration, data);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.GREEN_REGISTRATION_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GREEN_REGISTRATION_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* getGreenOrganization(action) {
  const { formActions, values } = action;
  try {
    const response = yield call(Api.getHeliosOrganizations, values.subjectId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      payload: data,
      subjectId: values.subjectId,
      type: types.GET_GREEN_ORGANIZATION_SUCCESS,
    });
    const company = yield select(getRegistrationCompanySelector);
    if (company.name !== '' && company.canBeRegistered) {
      yield put({ type: types.GREEN_REGISTRATION_HIDE_DIALOG });
      yield put({ type: types.REGISTRATION_CONFIRM_SHOW_DIALOG });
    } else if (company.name !== '' && !company.canBeRegistered) {
      yield put(addSnackbarError('Specified ID is already in use. Company cannot be created.'));
      yield formActions.resetForm();
      yield formActions.setSubmitting(false);
    }
  } catch (error) {
    yield put(errorHelper(types.GET_GREEN_ORGANIZATION_FAILURE, error));
  }
}

export function* getRegistrationsList({ filter, order, page, params, rowsPerPage }) {
  try {
    const response = yield call(Api.getRegistrations, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_REGISTRATIONS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_REGISTRATIONS_FAILURE, error));
  }
}

export function* resendRegistrationEmail({ id }) {
  try {
    const values = { id };
    const response = yield call(Api.resendEmail, values);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.RESEND_REGISTRATION_EMAIL_SUCCESS });
    yield put(addSnackbarSuccess('Confirmation e-mail was resent successfully.'));
  } catch (error) {
    yield put({
      error: error.message ? error.message : error,
      type: types.RESEND_REGISTRATION_EMAIL_FAILURE,
    });
    yield put(addSnackbarError('Confirmation e-mail was not resent.'));
  }
}

export function* detailUser({ companyId, userId }) {
  yield put(companyActions.loadCompany(companyId));
  yield take([companyTypes.LOAD_COMPANY_SUCCESS]);
  try {
    const response = yield call(userApi.getCompanyAdminDetail, companyId, userId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: userTypes.DETAIL_USER_SUCCESS });
    const path = PATHS.SUPERADMIN_COMPANY_ADMIN_DETAIL.replace(':id', userId);
  } catch (error) {
    yield put(errorHelper(userTypes.DETAIL_USER_FAILURE, error));
  }
}

export function* detailCompany({ companyId }) {
  yield put(companyActions.loadCompany(companyId));
  yield take([companyTypes.LOAD_COMPANY_SUCCESS]);
}

export function* getSuperadminBatches(action) {
  const { filter, order, page, params, rowsPerPage } = action;
  try {
    const response = yield call(Api.getBatches, filter.fulltext, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_SUPERADMIN_BATCHES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_SUPERADMIN_BATCHES_FAILURE, error));
  }
}

export function* getSuperadminBatchDetail({ id }) {
  try {
    const response = yield call(Api.getBatchDetail, id);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_SUPERADMIN_BATCH_DETAIL_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_SUPERADMIN_BATCH_DETAIL_FAILURE, error));
  }
}

export function* getSuperadminBatchCodes({ id }) {
  try {
    const response = yield call(Api.getBatchCodes, id);
    yield responseValidateStatusOrThrow(response);
    const { results } = response.data;
    yield put({ payload: results, type: types.GET_SUPERADMIN_BATCH_CODES_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_SUPERADMIN_BATCH_CODES_FAILURE, error));
  }
}

export function* copySuperadminBatchCodes({ context, id }) {
  try {
    const response = yield call(Api.getBatchCodes, id, { limit: 1000000, offset: 0 });
    yield responseValidateStatusOrThrow(response);
    const { results } = response.data;
    const codes = results
      .filter((item) => {
        if (context === 'ACTIVE') {
          return item.state === 'CREATED';
        }
        return context === 'ALL';
      })
      .map((item) => item.code);
    copy(codes.join('\n'));
    if (context === 'ACTIVE') {
      yield put(addSnackbarSuccess(`All active codes (${codes.length}) copied to clipboard.`));
    } else {
      yield put(addSnackbarSuccess(`All codes (${codes.length}) copied to clipboard.`));
    }
  } catch (error) {
    yield put(addSnackbarError('Codes could not be copied. Please try again.'));
  }
}

export function* closeSuperadminBatch({ data, id }) {
  try {
    const response = yield call(Api.closeBatch, id, data);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CLOSE_SUPERADMIN_BATCH_SUCCESS });
    yield put(addSnackbarSuccess('The batch has been successfully closed.'));
  } catch (error) {
    yield put(errorHelper(types.CLOSE_SUPERADMIN_BATCH_FAILURE, error));
    yield put(addSnackbarError('The batch could not be closed. Please try again.'));
  }
}

// eslint-disable-next-line max-statements
export function* createSuperadminBatch({ formActions, values }) {
  yield formActions.setSubmitting(true);
  try {
    const { credit, ...rest } = values;
    const data = {
      ...rest,
      configuration: {
        credit,
        type: 'MOBILE_VIDEO_POOL_CREDIT',
      },
      context: {
        companyId: '',
        siteId: '',
      },
    };
    const response = yield call(Api.createBatch, data);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CREATE_SUPERADMIN_BATCH_SUCCESS });
    yield formActions.resetForm();
  } catch (error) {
    yield put(errorHelper(types.CREATE_SUPERADMIN_BATCH_FAILURE, error));
  } finally {
    yield formActions.setSubmitting(false);
  }
}

export function* getSerialNumbers(action) {
  const { filter, page, params, rowsPerPage } = action;
  try {
    const response = yield call(Api.getSerialNumbers, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_SUPERADMIN_SERIAL_NUMBERS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_SUPERADMIN_SERIAL_NUMBERS_FAILURE, error));
  }
}

export function* switchLogging({ enabled, serialNumber }) {
  try {
    const response = yield call(Api.switchLogging, serialNumber, { enabled });
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SWITCH_LOGGING_SUCCESS });
    yield put(addSnackbarSuccess(enabled ? 'Logging enabled successfully.' : 'Logging disabled successfully.'));
  } catch (error) {
    yield put(errorHelper(types.SWITCH_LOGGING_FAILURE, error));
  }
}

export function* removeDeviceFromLoggingList({ serialNumber }) {
  try {
    const response = yield call(Api.removeDeviceFromLoggingList, serialNumber);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.REMOVE_DEVICE_FROM_LOGGING_LIST_SUCCESS });
    yield put(addSnackbarSuccess('Device successfully removed from the list.'));
  } catch (error) {
    yield put(errorHelper(types.REMOVE_DEVICE_FROM_LOGGING_LIST_FAILURE, error));
  }
}

export default function* superadminSagas() {
  yield all([
    takeLatest(types.GET_SUPERADMIN_SITES_REQUEST, getSuperadminData),
    takeLatest(types.GET_SUPERADMIN_DEVICES_REQUEST, getSuperadminData),
    takeLatest(types.GET_SUPERADMIN_USERS_LIST_REQUEST, getSuperadminData),
    takeLatest(types.GET_SUPERADMIN_COMPANIES_REQUEST, getSuperadminCompanies),
    takeEvery(types.GET_GREEN_ORGANIZATION_REQUEST, getGreenOrganization),
    takeEvery(types.GREEN_REGISTRATION_REQUEST, createGreenRegistration),
    takeLatest(types.GET_REGISTRATIONS_REQUEST, getRegistrationsList),
    takeEvery(types.RESEND_REGISTRATION_EMAIL_REQUEST, resendRegistrationEmail),
    takeEvery(types.SUPERADMIN_DETAIL_USER_REQUEST, detailUser),
    takeEvery(types.SUPERADMIN_DETAIL_COMPANY_REQUEST, detailCompany),
    takeLatest(types.GET_SUPERADMIN_BATCHES_REQUEST, getSuperadminBatches),
    takeLatest(types.GET_SUPERADMIN_BATCH_DETAIL_REQUEST, getSuperadminBatchDetail),
    takeLatest(types.GET_SUPERADMIN_BATCH_CODES_REQUEST, getSuperadminBatchCodes),
    takeLatest(types.COPY_SUPERADMIN_BATCH_CODES, copySuperadminBatchCodes),
    takeLatest(types.CLOSE_SUPERADMIN_BATCH_REQUEST, closeSuperadminBatch),
    takeEvery(types.CREATE_SUPERADMIN_BATCH_REQUEST, createSuperadminBatch),
    takeLatest(types.GET_SUPERADMIN_SERIAL_NUMBERS_REQUEST, getSerialNumbers),
    takeEvery(types.SWITCH_LOGGING_REQUEST, switchLogging),
    takeEvery(types.REMOVE_DEVICE_FROM_LOGGING_LIST_REQUEST, removeDeviceFromLoggingList),
  ]);
}
