import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FE_ROLES } from '../../config/consts';
import { IModalBase } from '../../modules/commonTypes';
import { actionsTS, selectors } from '../../modules/user';
import { TUserActionContext } from '../../modules/user/actionsTS';
import { IUser } from '../../modules/user/store';
import { isUserGuest } from '../../modules/user/utils';
import { ActionModal } from '../_DesignSystemWrappers/Modal/ActionModal';
import { PinPanel } from '../PinPanel';
import UserInfo from '../UserInfo';
import messages from './messages';

interface IUserPinModalNew extends IModalBase {
  user: IUser;
  context: TUserActionContext;
}

function UserPinModalNew({ context, onClose, open, user }: IUserPinModalNew) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { pinData } = {
    pinData: useSelector(selectors.getGeneratedUserPinData),
  };

  const hasError = useSelector(selectors.hasGeneratedUserPinError);

  function onSave() {
    dispatch(actionsTS.saveUserPin(user.id, pinData.id, context));
    onClose();
  }

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={onSave}
      primaryButtonText={
        user.role === FE_ROLES.LOGIN_LESS || isUserGuest(user.role)
          ? formatMessage(messages.userPinModalConfirmButtonLoginLess)
          : formatMessage(messages.userPinModalConfirmButton)
      }
      secondaryButtonText={formatMessage(messages.userPinModalCancelButton)}
      title={formatMessage(messages.userPinModalTitle)}
    >
      <Box pt={2}>
        <Typography variant="body2">
          <FormattedMessage {...messages.userPinModalAssigneeText} />
        </Typography>
      </Box>
      <Box pt={2}>
        <UserInfo user={user} />
      </Box>
      <Box pt={2}>
        <PinPanel
          hasError={hasError}
          isFetching={false}
          pin={pinData.pin || ''}
          onInit={() => {
            dispatch(actionsTS.generateUserPin(user.id));
          }}
          onGenerate={() => {
            dispatch(actionsTS.generateUserPin(user.id));
          }}
        />
      </Box>
      <Box py={2}>
        {user.role === FE_ROLES.LOGIN_LESS && (
          <Typography variant="body2">
            <FormattedMessage {...messages.userPinModalInfoText} />
          </Typography>
        )}
      </Box>
    </ActionModal>
  );
}

export default UserPinModalNew;
