import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetUserCredentialsValidityQuery } from '../../../../apiRtk/accessControlApi';
import { UserPinModalNew } from '../../../../components';
import { EnrichedIUser } from '../../../../components/UserAccessForm/SectionPin';
import { useUrlParams } from '../../../../helpers/urlParams';
import { useModal } from '../../../../hooks/useModal';
import { getApartmentDevices } from '../../../../modules/apartments/selectors';
import { VisitorModalContext } from '../../../../modules/commonTypes';
import { getSiteData } from '../../../../modules/sites/selectors';
import { getUsersPin } from '../../../../modules/user/selectors';
import { IUser } from '../../../../modules/user/store';
import { isUserGuest } from '../../../../modules/user/utils';
import VisitorModal from '../../../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import messages from './messages';

function GenerateNewPinButton({ user }: { user: IUser }) {
  const siteData = useSelector(getSiteData);
  const apartmentDevices = useSelector(getApartmentDevices).data || [];
  const { companyId, siteId, userId } = useUrlParams();
  const { Modal: UserPinModal, onOpen: onOpenUserPinModal } = useModal({
    Modal: UserPinModalNew,
  });
  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
  });

  const hasPin = !!useSelector(getUsersPin);

  const [enrichedUser, setEnrichedUser] = useState(user as EnrichedIUser);

  const { data: userCredentialsValidityData } = useGetUserCredentialsValidityQuery({
    companyId: companyId!.toString(),
    siteId: siteId!.toString(),
    userId: userId!.toString(),
  });

  useEffect(() => {
    setEnrichedUser({
      ...user,
      validFrom: userCredentialsValidityData?.validFrom,
      validTo: userCredentialsValidityData?.validTo,
    });
  }, [userCredentialsValidityData]);

  return (
    <>
      <Button
        data-testid="GenerateNewPinButton"
        disabled={hasPin}
        onClick={isUserGuest(user.role) ? onOpenVisitorUModal : onOpenUserPinModal}
        startIcon={<AddIcon />}
        variant="text"
      >
        <FormattedMessage {...messages.accessAndSecurityGenerateNewPin} />
      </Button>
      <UserPinModal context="SITE_USER_DETAIL" user={user} />
      <VisitorUModal
        msgContext={VisitorModalContext.AA_MODAL_NEW_GUEST_PIN}
        apartmentInfo={{ companyId: companyId!, siteId: siteId! }}
        apartmentDevices={user?.apartments.length ? apartmentDevices : []}
        siteName={siteData.name}
        user={enrichedUser}
        isRegenerate
      />
    </>
  );
}

export default GenerateNewPinButton;
