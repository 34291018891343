export const moduleName = 'superadmin';

export const SEARCH_TYPES = {
  COMPANY_SITE: 'company-site',
  DEVICE: 'device',
  USER: 'user',
};

export default {
  CLOSE_SUPERADMIN_BATCH_FAILURE: `${moduleName}/CLOSE_SUPERADMIN_BATCH_FAILURE`,
  CLOSE_SUPERADMIN_BATCH_REQUEST: `${moduleName}/CLOSE_SUPERADMIN_BATCH_REQUEST`,
  CLOSE_SUPERADMIN_BATCH_SUCCESS: `${moduleName}/CLOSE_SUPERADMIN_BATCH_SUCCESS`,
  COPY_SUPERADMIN_BATCH_CODES: `${moduleName}/COPY_SUPERADMIN_BATCH_CODES`,
  CREATE_SUPERADMIN_BATCH_FAILURE: `${moduleName}/CREATE_SUPERADMIN_BATCH_FAILURE`,
  CREATE_SUPERADMIN_BATCH_REQUEST: `${moduleName}/CREATE_SUPERADMIN_BATCH_REQUEST`,
  CREATE_SUPERADMIN_BATCH_SUCCESS: `${moduleName}/CREATE_SUPERADMIN_BATCH_SUCCESS`,
  GET_GREEN_ORGANIZATION_FAILURE: `${moduleName}/GET_GREEN_ORGANIZATION_FAILURE`,
  GET_GREEN_ORGANIZATION_REQUEST: `${moduleName}/GET_GREEN_ORGANIZATION_REQUEST`,
  GET_GREEN_ORGANIZATION_SUCCESS: `${moduleName}/GET_GREEN_ORGANIZATION_SUCCESS`,
  GET_REGISTRATIONS_FAILURE: `${moduleName}/GET_REGISTRATIONS_FAILURE`,
  GET_REGISTRATIONS_REQUEST: `${moduleName}/GET_REGISTRATIONS_REQUEST`,
  GET_REGISTRATIONS_SUCCESS: `${moduleName}/GET_REGISTRATIONS_SUCCESS`,
  GET_SUPERADMIN_BATCH_CODES_FAILURE: `${moduleName}/GET_SUPERADMIN_BATCH_CODES_FAILURE`,
  GET_SUPERADMIN_BATCH_CODES_REQUEST: `${moduleName}/GET_SUPERADMIN_BATCH_CODES_REQUEST`,
  GET_SUPERADMIN_BATCH_CODES_SUCCESS: `${moduleName}/GET_SUPERADMIN_BATCH_CODES_SUCCESS`,
  GET_SUPERADMIN_BATCH_DETAIL_FAILURE: `${moduleName}/GET_SUPERADMIN_BATCH_DETAIL_FAILURE`,
  GET_SUPERADMIN_BATCH_DETAIL_REQUEST: `${moduleName}/GET_SUPERADMIN_BATCH_DETAIL_REQUEST`,
  GET_SUPERADMIN_BATCH_DETAIL_SUCCESS: `${moduleName}/GET_SUPERADMIN_BATCH_DETAIL_SUCCESS`,
  GET_SUPERADMIN_BATCHES_FAILURE: `${moduleName}/GET_SUPERADMIN_BATCHES_FAILURE`,
  GET_SUPERADMIN_BATCHES_REQUEST: `${moduleName}/GET_SUPERADMIN_BATCHES_REQUEST`,
  GET_SUPERADMIN_BATCHES_SUCCESS: `${moduleName}/GET_SUPERADMIN_BATCHES_SUCCESS`,
  GET_SUPERADMIN_COMPANIES_FAILURE: `${moduleName}/GET_SUPERADMIN_COMPANIES_FAILURE`,
  GET_SUPERADMIN_COMPANIES_REQUEST: `${moduleName}/GET_SUPERADMIN_COMPANIES_REQUEST`,
  GET_SUPERADMIN_COMPANIES_SUCCESS: `${moduleName}/GET_SUPERADMIN_COMPANIES_SUCCESS`,
  GET_SUPERADMIN_DEVICES_FAILURE: `${moduleName}/GET_SUPERADMIN_DEVICES_FAILURE`,
  GET_SUPERADMIN_DEVICES_REQUEST: `${moduleName}/GET_SUPERADMIN_DEVICES_REQUEST`,
  GET_SUPERADMIN_DEVICES_SUCCESS: `${moduleName}/GET_SUPERADMIN_DEVICES_SUCCESS`,
  GET_SUPERADMIN_SERIAL_NUMBERS_FAILURE: `${moduleName}/GET_SUPERADMIN_SERIAL_NUMBERS_FAILURE`,
  GET_SUPERADMIN_SERIAL_NUMBERS_REQUEST: `${moduleName}/GET_SUPERADMIN_SERIAL_NUMBERS_REQUEST`,
  GET_SUPERADMIN_SERIAL_NUMBERS_SUCCESS: `${moduleName}/GET_SUPERADMIN_SERIAL_NUMBERS_SUCCESS`,
  GET_SUPERADMIN_SITES_FAILURE: `${moduleName}/GET_SUPERADMIN_SITES_FAILURE`,
  GET_SUPERADMIN_SITES_REQUEST: `${moduleName}/GET_SUPERADMIN_SITES_REQUEST`,
  GET_SUPERADMIN_SITES_SUCCESS: `${moduleName}/GET_SUPERADMIN_SITES_SUCCESS`,
  GET_SUPERADMIN_USERS_LIST_FAILURE: `${moduleName}/GET_SUPERADMIN_USERS_LIST_FAILURE`,
  GET_SUPERADMIN_USERS_LIST_REQUEST: `${moduleName}/GET_SUPERADMIN_USERS_LIST_REQUEST`,
  GET_SUPERADMIN_USERS_LIST_SUCCESS: `${moduleName}/GET_SUPERADMIN_USERS_LIST_SUCCESS`,
  GREEN_REGISTRATION_FAILURE: `${moduleName}/GREEN_REGISTRATION_FAILURE`,
  GREEN_REGISTRATION_HIDE_DIALOG: `${moduleName}/GREEN_REGISTRATION_HIDE_DIALOG`,
  GREEN_REGISTRATION_REQUEST: `${moduleName}/GREEN_REGISTRATION_REQUEST`,
  GREEN_REGISTRATION_SHOW_DIALOG: `${moduleName}/GREEN_REGISTRATION_SHOW_DIALOG`,
  GREEN_REGISTRATION_SUCCESS: `${moduleName}/GREEN_REGISTRATION_SUCCESS`,
  REGISTRATION_CONFIRM_HIDE_DIALOG: `${moduleName}/REGISTRATION_CONFIRM_HIDE_DIALOG`,
  REGISTRATION_CONFIRM_SHOW_DIALOG: `${moduleName}/REGISTRATION_CONFIRM_SHOW_DIALOG`,
  REGISTRATION_SUCCESS_HIDE_MODAL: `${moduleName}/REGISTRATION_SUCCESS_HIDE_MODAL`,
  REGISTRATION_SUCCESS_SHOW_MODAL: `${moduleName}/REGISTRATION_SUCCESS_SHOW_MODAL`,
  REMOVE_DEVICE_FROM_LOGGING_LIST_FAILURE: `${moduleName}/REMOVE_DEVICE_FROM_LOGGING_LIST_FAILURE`,
  REMOVE_DEVICE_FROM_LOGGING_LIST_REQUEST: `${moduleName}/REMOVE_DEVICE_FROM_LOGGING_LIST_REQUEST`,
  REMOVE_DEVICE_FROM_LOGGING_LIST_SUCCESS: `${moduleName}/REMOVE_DEVICE_FROM_LOGGING_LIST_SUCCESS`,
  RESEND_REGISTRATION_EMAIL_FAILURE: `${moduleName}/RESEND_REGISTRATION_EMAIL_FAILURE`,
  RESEND_REGISTRATION_EMAIL_HIDE_DIALOG: `${moduleName}/RESEND_REGISTRATION_EMAIL_HIDE_DIALOG`,
  RESEND_REGISTRATION_EMAIL_REQUEST: `${moduleName}/RESEND_REGISTRATION_EMAIL_REQUEST`,
  RESEND_REGISTRATION_EMAIL_SHOW_DIALOG: `${moduleName}/RESEND_REGISTRATION_EMAIL_SHOW_DIALOG`,
  RESEND_REGISTRATION_EMAIL_SUCCESS: `${moduleName}/RESEND_REGISTRATION_EMAIL_SUCCESS`,
  SET_SUPERADMIN_SEARCH_TYPE: `${moduleName}/SET_SUPERADMIN_SEARCH_TYPE`,
  SIDEBAR_MOBILE_SHOW: `${moduleName}/SIDEBAR_MOBILE_SHOW`,
  SUPERADMIN_DETAIL_COMPANY_REQUEST: `${moduleName}/SUPERADMIN_DETAIL_COMPANY_REQUEST`,
  SUPERADMIN_DETAIL_USER_REQUEST: `${moduleName}/SUPERADMIN_DETAIL_USER_REQUEST`,
  SWITCH_LOGGING_FAILURE: `${moduleName}/SWITCH_LOGGING_FAILURE`,
  SWITCH_LOGGING_REQUEST: `${moduleName}/SWITCH_LOGGING_REQUEST`,
  SWITCH_LOGGING_SUCCESS: `${moduleName}/SWITCH_LOGGING_SUCCESS`,
};
