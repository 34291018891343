import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import useBreakpoints from '../../../../helpers/useBreakpoints';
import { TYPE_DESCRIPTOR } from '../../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../../modules/notifications/actions';
import { containsOneOfSpecificErrors } from '../../../../ory/errors/errorParser';
import { IUseRecoveryApi, useOryRecoveryApi } from '../../../../ory/hooks/oryApi';
import { useOryResponseParser } from '../../../../ory/hooks/oryResponseParser';
import {
  IOryRecoveryFlow,
  isIOryRecoveryCodeMaxAttempts,
  OryErrorMessage,
  OryErrorNamedMessageId,
  OryResponse,
} from '../../../../ory/types';
import messages from './messages';
import { useTimestamp } from './useTimestamp';
import {
  defaultValues,
  FormFieldNames,
  getRecoveryCodeFormValidationSchema,
  IRecoveryCodeFormData,
} from './validationScheme';

export interface IUseRecoveryCodeFormProps {
  recoveryFlowData: IOryRecoveryFlow;
  email: string;
  onSuccess: () => void;
  onRecoveryFlowRestart: (newFlowId?: string) => Promise<boolean>;
  onRecoveryProcessRestart: () => void;
  resendRecoveryCode: IUseRecoveryApi['resendRecoveryCode'];
}

export interface IUseRecoveryCodeForm {
  formData: UseFormReturn<IRecoveryCodeFormData>;
  buttonsDirection: 'column' | 'row';
  buttonsSpacing: number;
  hasNewFlowData: boolean;
  formSubmit: (values: IRecoveryCodeFormData) => Promise<void>;
  resendCode: () => Promise<void>;
}

export function useRecoveryCodeForm({
  email,
  onRecoveryFlowRestart,
  onRecoveryProcessRestart,
  onSuccess,
  recoveryFlowData,
  resendRecoveryCode,
}: IUseRecoveryCodeFormProps): IUseRecoveryCodeForm {
  const dispatch = useDispatch();
  const { downSm } = useBreakpoints();
  const { parseOryResponse } = useOryResponseParser();
  const { isTimestampActive } = useTimestamp();
  const { formatMessage } = useIntl();
  const [newFlowData, setNewFlowData] = useState<{ id?: string } | undefined>(undefined);
  const { confirmOryRecoveryFlow } = useOryRecoveryApi();

  const buttonsDirection = downSm ? 'column' : 'row';
  const buttonsSpacing = downSm ? 2 : 1;

  const formData = useForm<IRecoveryCodeFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getRecoveryCodeFormValidationSchema(formatMessage)),
  });

  const prepareRestartOfFlowOnSpecificError = (error?: OryErrorMessage): void => {
    if (error) {
      if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Recovery_flow_expired_4060005])) {
        setNewFlowData({});
        return;
      }

      if (
        containsOneOfSpecificErrors(error, [
          OryErrorNamedMessageId.Security_csrf_violation,
          OryErrorNamedMessageId.Session_refresh_required,
        ])
      ) {
        onRecoveryProcessRestart();
        return;
      }

      if (isIOryRecoveryCodeMaxAttempts(error)) {
        setNewFlowData({ id: error.detail.data.newFlowId });
      }
    }
  };

  const onResendCodeSuccess = () => {
    dispatch(addSnackbarSuccess(messages.ImsResetPasswordResendCodeSuccess));
  };

  const processResendRecoveryCodeResponse = (response: OryResponse) => {
    parseOryResponse({
      onError: (error) => {
        prepareRestartOfFlowOnSpecificError(error);
      },
      onSuccess: onResendCodeSuccess,
      response,
      validationField: {
        fieldName: FormFieldNames.RecoveryCode,
        formData,
      },
    });
  };

  const processConfirmOryRecoveryFlowResponse = (response: OryResponse) => {
    parseOryResponse({
      onError: (error) => {
        prepareRestartOfFlowOnSpecificError(error);
      },
      onSuccess,
      response,
      validationField: {
        fieldName: FormFieldNames.RecoveryCode,
        formData,
      },
    });
  };

  const resendCode = async () => {
    if (!isTimestampActive()) {
      formData.resetField(FormFieldNames.RecoveryCode);
      if (newFlowData) {
        const wasRecoveryFlowSuccessfullyRestarted = await onRecoveryFlowRestart(newFlowData.id);
        if (wasRecoveryFlowSuccessfullyRestarted) {
          setNewFlowData(undefined);
          onResendCodeSuccess();
        }
      } else {
        const resendRecoveryCodeResponse = await resendRecoveryCode({
          csrfToken: recoveryFlowData.csrfToken,
          email,
          id: recoveryFlowData.id,
        });
        processResendRecoveryCodeResponse(resendRecoveryCodeResponse);
      }
    } else {
      dispatch(addSnackbarError(messages.ImsResetPasswordResendCodeError, TYPE_DESCRIPTOR));
    }
  };

  const formSubmit = async (values: IRecoveryCodeFormData) => {
    const response = await confirmOryRecoveryFlow({
      code: values.recoveryCode,
      csrfToken: recoveryFlowData.csrfToken,
      id: recoveryFlowData.id,
    });
    processConfirmOryRecoveryFlowResponse(response);
  };

  return {
    buttonsDirection,
    buttonsSpacing,
    formData,
    formSubmit,
    hasNewFlowData: !!newFlowData,
    resendCode,
  };
}
